<template>
  <div class="p-1">
    <div class="rounded bg-white" >
      <div class="p-2 d-flex">
        <p class="p-0 mr-auto my-auto text-gray">{{ title }}</p>
        <ListButtonActions :options="options" :id="id" />
      </div>
      <radarChart  :id="id" class="col-12 px-3 pb-3 bg-white radarchart"/>
    </div>
  </div>
</template>
<script>
import radarChart from "@/components/charts/chartjs/radarChart.vue";
import ListButtonActions from "./ListButtonActions.vue";

export default {
  components: { radarChart, ListButtonActions },
  props: {
    options: Object,
    title: String,
  },
  data() {
    return {
      id: "piechart" + parseInt(Math.random() * 100),
    };
  },
};
</script>

<style>
.c3-axis-x-label,
.c3-axis-y-label {
  font-weight: bold;
}
*:fullscreen,
*:-webkit-full-screen,
*:-moz-full-screen {
  background-color: rgb(255, 255, 255);
  padding: 50px;
}
.radarchart  {
  height:266px !important;
}
#radar-chart{
  height:265px!important;
  color:black !important;
  /* width:auto !important; */
  margin:auto;
}
</style>
