<script>
import { Radar } from "vue-chartjs";

export default {
  name: "area-chart",
  extends: Radar,
  data() {
    return {
      datacollection: {
        defaultFontSize: "black",
        size: 15,
        labels: ["Projet 1", "Projet 2", "Projet 3", "Projet 4", "Projet 5"],
        datasets: [
          {
            data: [65, 59, 90, 81, 56],
            fill: false,
color:'black',
        defaultFontSize: 15,

            backgroundColor: "rgba(255, 99, 132, 0.2)",
            borderColor: "rgb(255, 99, 132)",
            pointBackgroundColor: "rgb(255, 99, 132)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgb(255, 99, 132)",
          },
        ],
      },
      options: {
        labels: {
          color: "black",
          defaultFontSize: 15,
        },

        legend: {
          display: false,
        },
        elements: {
          line: {
            borderWidth: 5,
          },
        },
        aspectRatio: false,
        responsive: true,
      },
    };
  },
  mounted() {
    this.renderChart(this.datacollection, this.options);
  },
};
</script>
