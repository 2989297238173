<template>
  <section class="content-wrapper p-0 m-0">
    <div class="d-flex my-1 p-1">
      <!-- <ListButtonActions id="travauxgdpr-section" class="ml-auto" /> -->
    </div>
    <b-tabs
      id="project"
      :value="$store.state.tabs.activeTab"
      @changed="activeTab = $store.state.tabs.activeTab"
      @activate-tab="(newTabIndex) => $store.dispatch('tabs/changeTab', newTabIndex)"
      class="tab-solid tab-solid-primary"
    >
      <b-tab title="Projets" lazy>
        <div class="p-0 m-0 col-12">
          <div class="row row-eq-height p-0 m-0 col-12 mt-2">
            <!-- charts... -->

            <customLineChart
              title="Nombre d'impacts projet sur le patrimoine"
              :options="NombreimpactProjetPatrimoineOptions"
              class="col-6"
            />
            <customBarChart
              :options="NombreProjetParObjectifOptions"
              title="Nombre de projets par objectifs stratégique"
              class="col-6"
            />
            <customBarChart
              :options="NombreProjetParVisionOptions"
              title="Nombre de projets par vision"
              class="col-6"
            />
            <customRadarChart title="Radar de risques par projet " class="col-6" />
            <customBarChart
              :options="TauxRespectPrincipeProjetOptions"
              title="Taux de respect des principes par projet"
              class="col-6"
            />
            <customBarChart
              :options="typeImpactObjectOptions"
              title="Type d'impact sur les objects (Patrimoine)"
              class="col-6"
            />
          </div>
        </div>
      </b-tab>
      <b-tab title="Feuille de route" lazy>
        <div class="p-0 m-0 col-12">
          <div class="row row-eq-height p-0 m-0 col-12 mt-2">
            <!-- charts... -->
            <customLineChart
              :options="NombreFDRDomaineOptions"
              class="col-6"
              title="Nombre de FdR par domaine"
            />

            <customBarChart
              :options="NombreFDRParObjectifOptions"
              title="Nombre de FdR par objectifs stratégiques"
              class="col-6"
            />

            <customBarChart
              :options="NombreFDRParVisionOptions"
              title="Nombre de FDR par vision"
              class="col-6"
            />
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </section>
</template>

<script>
// import AnalyticsStatistics from "../components/AnalyticsStatistics.vue";
import customBarChart from "../components/customBarChart.vue";
import customLineChart from "../components/customLineChart.vue";
// import ListButtonActions from "../components/ListButtonActions.vue";
import customRadarChart from "../components/customRadarChart.vue";

import {
  NombreProjetParObjectifOptions,
  // TraitementDCPTypeHbergementOptions,
  NombreimpactProjetPatrimoineOptions,
  NombreProjetParVisionOptions,
  typeImpactObjectOptions,
  TauxRespectPrincipeProjetOptions,
  NombreFDRDomaineOptions,
  NombreFDRParObjectifOptions,
  NombreFDRParVisionOptions,
} from "../chartsData.js";

export default {
  components: {
    customBarChart,
    customLineChart,
    // AnalyticsStatistics,
    // ListButtonActions,
    customRadarChart,
  },
  data() {
    return {
      firstSection: [
        {
          title: "Applications traitées : Purge",
          value: 65,
        },
        {
          title: "Applications traitées : Anonymisation",
          value: 20,
        },
        {
          title: "Applications traitées : Restriction d'Accès",
          value: 79,
        },
        {
          title: "Applications non traitées",
          value: 170,
        },
      ],
      NombreProjetParObjectifOptions,
      NombreProjetParVisionOptions,
      NombreimpactProjetPatrimoineOptions,
      typeImpactObjectOptions,
      TauxRespectPrincipeProjetOptions,
      NombreFDRDomaineOptions,
      NombreFDRParObjectifOptions,
      NombreFDRParVisionOptions,
    };
  },
};
</script>

<style>
#project .nav-item {
  background-color: white;
}
.custom-icon-size {
  font-size: 4rem;
}
.custom-title-size {
  font-size: 0.8rem;
}
.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
</style>
